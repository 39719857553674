import React from 'react';
import styled from 'styled-components';
import Text from '../../text';

const SIZE = 40;

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Number = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  font-weight: 1000;
  color: ${ ({ theme }) => theme.color.white };
  text-transform: uppercase;
  margin: 5px 0 0 0;
`;
// -----------------------------------------------------------------------------
const BG = styled.div`
  background-color: ${ ({ theme }) => theme.color.primary };
  height: ${ SIZE }px;
  width: ${ SIZE }px;
  border-radius: 50%;
`;
// -----------------------------------------------------------------------------
const Nbr = ({ children }) => (
  <BG className="flex-none mr2">
    <Number className="center">
      {children}
    </Number>
  </BG>
);
// -----------------------------------------------------------------------------
const Title = ({ children }) => (
  <Text size="S" color="secondary" className="my1">
    {children}
  </Text>
);
// -----------------------------------------------------------------------------
const Body = ({ children }) => (
  <Text size="XS">
    {children}
  </Text>
);
// -----------------------------------------------------------------------------
// COMPONENTS:
// -----------------------------------------------------------------------------
export const Header = props => (
  <div {...props}>
    <Text size="L" className="center">
      Frequently Asked Questions
    </Text>
  </div>
);

export const OwnersOne = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>1</Nbr>
      <div>
        <Title>
          How do I write an appealing listing?
        </Title>
        <Body>
          a) Make sure to upload multiple photos of your home and pet(s). Attractive photos of your home go a long way (ex: clean and tidy, not blurry, good light).
          <br /><br />
          b) Include an accurate description of your home and list all the amenities. Describing your neighborhood and it’s local attractions will also help entice sitters.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersTwo = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>2</Nbr>
      <div>
        <Title>
          How do I meet to exchange keys?
        </Title>
        <Body>
          Most people arrange in one of the following ways:
          <br />
          a) Meet at your home in order to tour the home and introduce
          them to your pet(s) (most recommended).
          <br />
          b) Use an e-entry.
          <br />
          c) Use a lockbox key.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersThree = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>3</Nbr>
      <div>
        <Title>
          How do I prepare the home for the sitter’s stay?
        </Title>
        <Body>
          a) Provide the sitter with clean sheets and towels.
          <br />
          b) Clean and tidy up your home.
          <br />
          c) Fill out the information in the Sitter Guide that we provide and share it with the sitter.
          <br />
          d) Some people prefer to stow away personal, private, or valuable items or leave them at a friend’s house for safekeeping.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersFour = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>4</Nbr>
      <div>
        <Title>
          What do I do once a trip has started?
        </Title>
        <Body>
          Enjoy your trip and travel with peace of mind knowing your pet is in good hands. Check in with the pet sitter if you have any questions.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersFive = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>5</Nbr>
      <div>
        <Title>
          What happens after the trip has ended?
        </Title>
        <Body>
          a) Make arrangements to return your keys.
          <br />
          b) Write an honest review with specific details about your experience and accurately rate the pet sitter to help future pet owners.
          <br />
          c) Share feedback with the Pet Digs team.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersSix = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>6</Nbr>
      <div>
        <Title>
          What if the pet sitter cancels right before the trip?
        </Title>
        <Body>
          While we encourage all sitters to fully commit to their trips we understand that emergencies come up. We urge you to have a backup plan just in case such as a friend, family member, kennel, etc. Please notify Pet Digs immediately at <b>support@mypetdigs.com</b> and we will repost your listing to help you find someone else asap.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersSeven = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>7</Nbr>
      <div>
        <Title>
          What happens if a pet sitter is no longer able to continue with the sit?
        </Title>
        <Body>
          We urge you to have a backup plan just in case such as a friend, family member, kennel, etc. Please notify Pet Digs immediately at <b>support@mypetdigs.com</b>.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersEight = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>8</Nbr>
      <div>
        <Title>
          What if I need to cancel my trip?
        </Title>
        <Body>
          We encourage you to fully commit to this trip as your pet sitters might be making non-refundable travel arrangements to come. However, we understand emergencies come up, so please notify the sitter and Pet Digs immediately at <b>support@mypetdigs.com</b>.
        </Body>
      </div>
    </div>
  </div>
);

export const OwnersNine = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>9</Nbr>
      <div>
        <Title>
          What if I need to come back early from my trip?
        </Title>
        <Body>
          Please notify the sitter and Pet Digs immediately at <b>support@mypetdigs.com</b>.
        </Body>
      </div>
    </div>
  </div>
);
// -----------------------------------------------------------------------------
// SITTERS:
// -----------------------------------------------------------------------------
export const SittersOne = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>1</Nbr>
      <div>
        <Title>
          Can couples or families be pet sitters?
        </Title>
        <Body>
          a) Couples can join as long as both of them are at least 21 years old and are United States residents. Just add your partner as a “Co-sitter” in your profile.
          <br /><br />
          b) Families can join as long as the primary sitter is the legal guardian, at least 21 years old and a United States resident. Please indicate how many minors you are traveling with in your profile.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersTwo = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>2</Nbr>
      <div>
        <Title>
          Do I have to do a background check?
        </Title>
        <Body>
          a) Pet sitters are required to complete a background check before publishing their profile and interacting with pet owners. Background checks are run through a trusted third party, Turn Technologies, and the cost is included.
          <br /><br />
          b) Co-sitters are required to complete a background check as well.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersThree = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>3</Nbr>
      <div>
        <Title>
          How long does it take to run the background check?
        </Title>
        <Body>
          a) Upon requesting the background check in the app you will receive an email from <b>support@turning.io</b>. Make sure you click "continue the process" and follow the instructions to start the check.
          <br /><br />
          b) We run a 7 year county criminal check so the process could take anywhere from a few hours up to 5 days. We will notify you via email when it's complete.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersFour = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>4</Nbr>
      <div>
        <Title>
          What are references?
        </Title>
        <Body>
          a) Pet sitters are required to provide at least one outside reference before publishing their profile and interacting with pet owners. References can be from another pet owner, manager, colleague, friend etc.
          <br /><br />
          b) Pet Digs will contact your reference via email to verify and upload a quote from them to your profile. We will also specify what type of reference they are (pet owner, manager, colleague, friend, etc.).
        </Body>
      </div>
    </div>
  </div>
);

export const SittersFive = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>5</Nbr>
      <div>
        <Title>
          Do I need to add available dates?
        </Title>
        <Body>
          a) No, your profile will still show up in the search results with “flexible dates”. Please note that a pet owner might be less inclined to contact you if they don’t see your available dates.
          <br /><br />
          b) Feel free to add as many dates that you are available, it will help pet owners find you if their dates match up with yours.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersSix = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>6</Nbr>
      <div>
        <Title>
          What to do before a trip?
        </Title>
        <Body>
          a) Confirm the arrival and departure times with the pet owner.
          <br /><br />
          b) Make arrangements to exchange keys with the pet owner. The pet owner should provide a Sitter Guide with detailed pet and home care instructions.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersSeven = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>7</Nbr>
      <div>
        <Title>
          How do I wrap up a trip?
        </Title>
        <Body>
          a) Please clean and tidy up the home (leave it in the same condition that you found it).
          <br /><br />
          b) Make arrangements to return the keys.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersEight = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>8</Nbr>
      <div>
        <Title>
          What happens after the trip has ended?
        </Title>
        <Body>
          a) Write an honest review with specific details about your experience and accurately rate the pet owner to help future pet sitters.
          <br /><br />
          b) Share feedback with the Pet Digs team.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersNine = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>9</Nbr>
      <div>
        <Title>
          What if the pet owner cancels right before the trip and I have already made travel arrangements?
        </Title>
        <Body>
          While we encourage all pet owners to fully commit to their trips we understand that emergencies come up and plans change. We urge you to have a backup plan just in case (hotel, airbnb, friend’s place) or book refundable travel. Please notify Pet Digs immediately at <b>support@mypetdigs.com</b>.
        </Body>
      </div>
    </div>
  </div>
);

export const SittersTen = props => (
  <div {...props}>
    <div className="flex">
      <Nbr>10</Nbr>
      <div>
        <Title>
          What if I need to cancel my trip?
        </Title>
        <Body>
          We encourage you to fully commit to this trip as the pet owners are relying on you. However, we understand emergencies come up so please notify the pet owner and Pet Digs immediately at <b>support@mypetdigs.com</b>.
        </Body>
      </div>
    </div>
  </div>
);
