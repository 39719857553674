import React from 'react';
import styled from 'styled-components';
import Section from '../../components/section';
import Divider from '../../components/divider';
import Text from '../../components/text';
import * as FAQ from '../../components/faq/content';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Div = styled.div`
  background-image: linear-gradient(170deg, #FAE3D4, #FFF8F3);
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const FAQDesktop = () => (
  <Div>
    <Section viewType="desktop">
      <div className="pt4" />
      <div className="pt4" />
      <FAQ.Header />

      <div className="py1" />

      <Text size="ML" color="secondary" className="center m0">
        Pet Owners
      </Text>
      <div className="py2" />
      <Divider style={{ maxWidth: '400px' }} className="mx-auto" />
      <div className="py2" />
      <div className="flex flex-wrap">
        <FAQ.OwnersOne className="col-6 mb3" />
        <FAQ.OwnersTwo className="col-6 mb3" />
        <FAQ.OwnersThree className="col-6 mb3" />
        <FAQ.OwnersFour className="col-6 mb3" />
        <FAQ.OwnersFive className="col-6 mb3" />
        <FAQ.OwnersSix className="col-6 mb3" />
        <FAQ.OwnersSeven className="col-6 mb3" />
        <FAQ.OwnersEight className="col-6 mb3" />
        <FAQ.OwnersNine className="col-6 mb3" />
      </div>

      <div className="py3" />

      <Text size="ML" color="secondary" className="center m0">
        Pet Sitters
      </Text>
      <div className="py2" />
      <Divider style={{ maxWidth: '400px' }} className="mx-auto" />
      <div className="py2" />
      <div className="flex flex-wrap">
        <FAQ.SittersOne className="col-6 mb3" />
        <FAQ.SittersTwo className="col-6 mb3" />
        <FAQ.SittersThree className="col-6 mb3" />
        <FAQ.SittersFour className="col-6 mb3" />
        <FAQ.SittersFive className="col-6 mb3" />
        <FAQ.SittersSix className="col-6 mb3" />
        <FAQ.SittersSeven className="col-6 mb3" />
        <FAQ.SittersEight className="col-6 mb3" />
        <FAQ.SittersNine className="col-6 mb3" />
        <FAQ.SittersTen className="col-6 mb3" />
      </div>
    </Section>
  </Div>
);

export default FAQDesktop;
