import React from 'react';
import ErrorBoundary from '../components/error-boundary';
import Layout from '../components/layout';
import FAQMobile from '../mobile-sections/faq';
import FAQDesktop from '../desktop-sections/faq';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const FaqPage = ({ location }) => (
  <ErrorBoundary>
    <Layout location={location}>
      <div className="hide-for-large">
        <FAQMobile />
      </div>
      <div className="show-for-large">
        <FAQDesktop />
      </div>
    </Layout>
  </ErrorBoundary>
);

export default FaqPage;
