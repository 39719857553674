import React from 'react';
import styled from 'styled-components';
import Section from '../../components/section';
import Divider from '../../components/divider';
import Text from '../../components/text';
import * as FAQ from '../../components/faq/content';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Div = styled.div`
  background-image: linear-gradient(170deg, #FAE3D4, #FFF8F3);
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const FAQMobile = () => (
  <Div>
    <Section viewType="mobile">
      <div className="pt4" />
      <div className="pt4" />
      <FAQ.Header />

      <div className="py1" />

      <Text size="ML" color="secondary" className="center m0">
        Pet Owners
      </Text>
      <div className="py2" />
      <Divider style={{ maxWidth: '400px' }} className="mx-auto" />
      <div className="py2" />
      <FAQ.OwnersOne className="mb3" />
      <FAQ.OwnersTwo className="mb3" />
      <FAQ.OwnersThree className="mb3" />
      <FAQ.OwnersFour className="mb3" />
      <FAQ.OwnersFive className="mb3" />
      <FAQ.OwnersSix className="mb3" />
      <FAQ.OwnersSeven className="mb3" />
      <FAQ.OwnersEight className="mb3" />
      <FAQ.OwnersNine className="mb3" />

      <div className="py3" />

      <Text size="ML" color="secondary" className="center m0">
        Pet Sitters
      </Text>
      <div className="py2" />
      <Divider style={{ maxWidth: '400px' }} className="mx-auto" />
      <div className="py2" />

      <FAQ.SittersOne className="mb3" />
      <FAQ.SittersTwo className="mb3" />
      <FAQ.SittersThree className="mb3" />
      <FAQ.SittersFour className="mb3" />
      <FAQ.SittersFive className="mb3" />
      <FAQ.SittersSix className="mb3" />
      <FAQ.SittersSeven className="mb3" />
      <FAQ.SittersEight className="mb3" />
      <FAQ.SittersNine className="mb3" />
      <FAQ.SittersTen className="mb3" />

    </Section>
  </Div>
);

export default FAQMobile;
